import React from "react"
import Layout from "../components/Layout"
import PageTitle from "../components/PageTitle"
import SectionTitle from "../components/SectionTitle"
import OneColSection from "../components/OneColSection"
import KonzerteHauptbuehne from "../components/KonzerteHauptbuehne"
import KonzerteNebentbuehne from "../components/KonzerteNebentbuehne"
import { graphql } from "gatsby"
import { now, timestampUnix } from "../components/dateFn"

function Konzerte(props) {
  const allConcerts = []
  const futureConcertsHauptB = []
  const futureConcertsNebenB = []
  
  for (
    let index = 0;
    index < props.data.prismic.allPages.edges.length;
    index++
  ) {
    const element = props.data.prismic.allPages.edges[index]
    
    //get only concerts in the future
    let endTimeUnix = ""
    if (element.node.body[0].primary) {
      endTimeUnix = timestampUnix(element.node.body[0].primary.end_time)
    }
    
    if (
      element.node.body[0].primary && element.node.body[0].primary
      .event_type === "Konzert"
    ) {
      allConcerts.push(element)
      //
    }
    
    if (
      endTimeUnix > now &&
      element.node.body[0].primary.event_place === "Hauptbühne"
    ) {
      futureConcertsHauptB.push(element)
    }
    
    if (
      element.node.body[0].primary && element.node.body[0].primary.event_type ===
      "Konzert" &&
      endTimeUnix > now &&
      element.node.body[0].primary.event_place === "Nebenbühne"
    ) {
      futureConcertsNebenB.push(element)
    }
    
    // pass some values down the query tree
    if (element.node.body[0].primary){
      element.node.body[0].primary.slug = element.node._meta.uid
      element.node.body[0].primary.pageTitle =
      element.node.page_title
    }
  }
  
  const pageSectionTitle1 = [
    { spans: [], type: "heading2", text: "Hauptbühne" },
  ]
  const pageSectionTitle2 = [
    { spans: [], type: "heading2", text: "Nebenbühne" },
  ]
  
  return (
    <Layout>
      <>
        <PageTitle
          pageTitle={props.data.prismic.allKonzertes.edges[0].node.page_title}
        />
        <OneColSection
          content={props.data.prismic.allKonzertes.edges[0].node.text}
        />
        {futureConcertsHauptB.length > 0 ? (
          <SectionTitle title={pageSectionTitle1} />
        ) : null}
        {futureConcertsHauptB.length > 0 ? (
          <KonzerteHauptbuehne events={futureConcertsHauptB} />
        ) : null}
        {futureConcertsNebenB.length > 0 ? (
          <SectionTitle title={pageSectionTitle2} />
        ) : null}
        {futureConcertsNebenB.length > 0 ? (
          <KonzerteNebentbuehne events={futureConcertsNebenB} />
        ) : null}
      </>
    </Layout>
  )
}

export default Konzerte

export const query = graphql`
  {
    prismic {
      allPages(
        where: { page_type: "Event", event_type: "Konzert" }
        sortBy: meta_firstPublicationDate_DESC
        lang: "de-ch"
      ) {
        totalCount
        edges {
          node {
            page_title
            _meta {
              uid
            }
            body {
              ... on PRISMIC_PageBodyEvents {
                type
                primary {
                  date_time
                  end_time
                  event_place
                  event_type
                  image
                  event_teaser
                }
              }
            }
          }
        }
      }
      allKonzertes(lang: "de-ch") {
        edges {
          node {
            page_title
            text
          }
        }
      }
    }
  }
`
